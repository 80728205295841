import React from "react"
import styles from "./subscription-form.module.scss"

import IconHeading from "../heading/icon-heading"

import JoinIcon from "../images/heading-join.svg"

/* eslint-disable */
export default () => (
  <section className={styles.newsletterSection}>
    <IconHeading text="Join the club" image={JoinIcon}/>

    <form action="https://okkotlin.us5.list-manage.com/subscribe/post?u=1d4470675b8a29054964e5880&amp;id=ddb4366fc0"
          method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
          target="_blank" noValidate>
      <div className={styles.subscribeForm} id="mc_embed_signup_scroll">
        <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Your email address"/>
        <div style={{ display: "none" }} aria-hidden="true">
          <input type="text"
                 name="b_1d4470675b8a29054964e5880_ddb4366fc0"
                 tabIndex="-1" value=""/></div>

        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"
               className="button" onClick={typeof fathom !== 'undefined' && fathom("trackGoal", "T4JJ9ESN", 0)}/>
      </div>
    </form>

    <p className={styles.newsletterDisclaimer}>Your email address will only be stored on <strong>Mailchimp</strong> for
      sending the newsletters. Won't be used for
      anything else.</p>
  </section>
)
