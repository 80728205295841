import React from "react"

import Container from "../components/container"
import SubscriptionForm from "../components/newsletter/subscription-form"
import Metadata from "../components/metadata"

export default () => {
  const listStyle = { marginLeft: "25px", marginBottom: "-10px" }

  return (
    <Container>
      <Metadata
        title="The OkKotlin Newsletter"
        description="Subscribe to the monthly newsletter on all things OkKotlin and more around the web. Learn more here."
      />

      <h1>The OkKotlin Newsletter</h1>

      <p>Subscribe to the monthly OkKotlin newsletter which goes out the <strong>1st day</strong> of each month, and get
        a content rich
        newsletter to your
        inbox <strong>for free</strong>.</p>

      <p>The goodies:</p>

      <p style={listStyle}><span role="img" aria-label="Monthly">📅</span> Articles published on OkKotlin</p>
      <p style={listStyle}><span role="img" aria-label="Hand-curated articles">👍🏻</span> Hand-curated articles on
        Kotlin from around the web</p>
      <p style={listStyle}><span role="img" aria-label="Podcast episodes">🎙</span> Collected podcast episodes worth your
        time</p>
      <p style={listStyle}><span role="img" aria-label="Useful libraries">📦</span> Useful libraries to supercharge your
        development</p>
      <p style={listStyle}><span role="img" aria-label="Zero spam">✅</span> Zero spam</p>
      <p style={listStyle}><span role="img" aria-label="Unsubscribe easily">✋🏻</span> Unsubscribe anytime, just one
        click</p>
      <p style={listStyle}><span role="img" aria-label="Privacy protected">🚫</span> Your email
        will <strong>never</strong> be shared
        with advertisers</p>

      <SubscriptionForm/>

    </Container>
  )
}
